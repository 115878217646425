<template>
  <main>
    <!-- ===== Hero ===== -->
    <div class="position-relative overflow-hidden bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/fabrix-bg.svg') + ')'}">
      <div class="container space-top-3 space-bottom-2 mt-lg-5">
        <div class="w-lg-85 text-center mx-lg-auto">
          <div class="mb-5">
            <h1 class="display-4 mb-3">Grow Your API Business with Cloud-Based <span class="text-primary">API Store</span></h1>
            <p class="lead">Build Your Own API Store and Share Your APIs to the World.</p>
          </div>
          <a class="btn btn-primary transition-3d-hover" href="https://provider-portal.fabrixapi.com/" target="_blank">Start for Free <i class="far fa-arrow-right ml-1" /></a>
        </div>
      </div>

      <div class="container space-bottom-2">
        <div class="position-relative w-90 w-md-75 mx-auto">
          <div class="card shadow-none overflow-hidden mx-lg-auto bg-transparent">
            <a href="javascript:;" data-toggle="modal" data-target="#videoModal" class="video-player">
              <img class="img-fluid video-player-preview" src="@/assets/img/academy/fabrix-api-portal.jpg">
              <div class="video-player-btn video-player-centered">
                <span class="video-player-icon video-player-icon-pill video-player-icon-primary">
                  <i class="fas fa-play-circle mr-2 font-size-2" /> Watch the Video
                </span>
              </div>
              <div class="embed-responsive embed-responsive-16by9">
                <div id="oahVideoIframe" />
              </div>
            </a>
          </div>
          <div id="videoModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="videoModalTitle" aria-hidden="true">
            <div class="modal-xl modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 id="videoModalTitle" class="modal-title" />
                  <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
                    <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                      <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                    </svg>
                  </button>
                </div>
                <div class="modal-body p-2 px-sm-4 pt-0 pb-4">
                  <div style="padding:52% 0 0 0;position:relative;">
                    <iframe src="https://www.youtube.com/embed/zf1mdUovbH8?autoplay=1&mute=1&loop=1" frameborder="0" allow="autoplay; fullscreen; loop;" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="FabriXAPI - What is API Portal" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="container space-3 mt-lg-6">
        <div class="row justify-content-lg-between align-items-center">
          <div class="col-lg-6 mb-7 mb-lg-0">
            <div class="w-md-85 w-lg-100 mx-auto text-center text-lg-left">
              <div class="mb-5">
                <h1 class="display-5 mb-3">Grow Your API Business with Cloud-Based <span class="text-primary">API Store</span></h1>
                <p class="lead">Build Your Own API Store and Share Your APIs to the World.</p>
              </div>
              <a class="btn btn-primary transition-3d-hover" href="https://provider-portal.fabrixapi.com/" target="_blank">Start for free <i class="far fa-arrow-right ml-1" /></a>
            </div>
          </div>

          <div class="col-lg-6">
            <div data-gtm-att="ga-intro-video-card" class="card shadow-none overflow-hidden mx-lg-auto bg-transparent">
              <a href="javascript:;" data-toggle="modal" data-target="#videoModal" class="video-player">
                <img class="img-fluid video-player-preview" src="@/assets/img/products/developer-portal/dp-endpoint.svg">
                <div class="video-player-btn video-player-centered">
                  <span class="video-player-icon video-player-icon-pill video-player-icon-primary">
                    <i class="fas fa-play-circle mr-2 font-size-2" /> Watch the Video
                  </span>
                </div>
                <div class="embed-responsive embed-responsive-4by3">
                  <div id="oahVideoIframe" />
                </div>
              </a>
            </div>
            <div id="videoModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="videoModalTitle" aria-hidden="true">
              <div class="modal-xl modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 id="videoModalTitle" class="modal-title" />
                    <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
                      <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                      </svg>
                    </button>
                  </div>
                  <div class="modal-body p-2 px-sm-4 pt-0 pb-4">
                    <div style="padding:52% 0 0 0;position:relative;">
                      <iframe src="https://www.youtube.com/embed/zf1mdUovbH8?autoplay=1&mute=1&loop=1" frameborder="0" allow="autoplay; fullscreen; loop;" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="FabriXAPI - What is API Portal" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

    </div>
    <!-- ===== End Hero ===== -->

    <!-- ===== Main Feature ===== -->
    <div class="container space-2">
      <div class="w-md-85 w-lg-75 text-center mx-md-auto mb-7">
        <h1>Build Your API Store Without Coding</h1>
        <p>FabriXAPI, the newly re-branded version of the OpenAPIHub API Hub & Portal Platform, is here to revolutionize your API business. Discover the immense potential of FabriXAPI and unlock unprecedented growth opportunities for your organization.</p>
      </div>

      <!-- Branded API Portal -->
      <div class="p-5 p-lg-7 mb-9" style="background-color: #e6f4fe; border-radius: 16px">
        <div class="row align-items-center">
          <div class="col-md-6 mb-5 mb-md-0 pr-md-5">
            <span class="d-block small font-weight-bold text-cap mb-2">Build It</span>
            <h2>Branded API Portal</h2>
            <div
              style="inline-size: 100px; block-size: 4px; margin-block-start: 20px; margin-block-end: 20px; border-radius: 5px; background-color: #57abf0"
            />
            <p class="mb-5">Create your very own customized API Portal effortlessly with just a few clicks, while enjoying the flexibility to configure it in real time.</p>
            <router-link :to="{ path: `/api-portal`}" class="font-weight-bold">
              Learn More <i class="far fa-arrow-right ml-1" />
            </router-link>
          </div>
          <div class="col-md-6">
            <img class="img-fluid" src="@/assets/svg/illustrations/branded-api-portal.png">
          </div>
        </div>
      </div>

      <!-- API Product Management -->
      <div class="p-5 p-lg-7 mb-9" style="background-color: #f8f7fe; border-radius: 16px">
        <div class="row align-items-center">
          <div class="col-md-6 mb-5 mb-md-0 pl-md-5 order-md-2">
            <span class="d-block small font-weight-bold text-cap mb-2">Showcase It</span>
            <h2>API Product Management</h2>
            <div
              style="inline-size: 100px; block-size: 4px; margin-block-start: 20px; margin-block-end: 20px; border-radius: 5px; background-color: #7460eb"
            />
            <p class="mb-5">Effortlessly launch and showcase your APIs on your branded API Portal. Customize access rights, pricing plans, subscriptions and more to meet your specific needs.</p>
            <router-link :to="{ path: `/api-management`}" class="font-weight-bold" style="color:#7460eb;">
              Learn More <i class="far fa-arrow-right ml-1" />
            </router-link>
          </div>
          <div class="col-md-6 order-md-1">
            <img class="img-fluid" src="@/assets/svg/illustrations/api-catalogue.svg">
          </div>
        </div>
      </div>

      <!-- API Monetization -->
      <div class="p-5 p-lg-7 mb-9" style="background-color: #fff8fb; border-radius: 16px">
        <div class="row align-items-center">
          <div class="col-md-6 mb-5 mb-md-0 pr-md-5">
            <span class="d-block small font-weight-bold text-cap mb-2">Sell It</span>
            <h2>API Monetization</h2>
            <div
              style="inline-size: 100px; block-size: 4px; margin-block-start: 20px; margin-block-end: 20px; border-radius: 5px; background-color: #ff3d90"
            />
            <p class="mb-5">FabriXAPI offers comprehensive API monetization features right out of the box, empowering you to effectively monetize your APIs and generate revenue.</p>
            <router-link :to="{ path: `/api-monetization`}" class="font-weight-bold" style="color:#ff3d90">
              Learn More <i class="far fa-arrow-right ml-1" />
            </router-link>
          </div>
          <div class="col-md-6">
            <img class="img-fluid" src="@/assets/svg/illustrations/api-portal-pricing.svg">
          </div>
        </div>
      </div>

      <!-- Subscription Management -->
      <div class="p-5 p-lg-7 mb-9" style="background-color: #fff8f2; border-radius: 16px">
        <div class="row align-items-center">
          <div class="col-md-6 mb-5 mb-md-0 pl-md-5 order-md-2">
            <span class="d-block small font-weight-bold text-cap mb-2">Manage It</span>
            <h2>Subscription Management</h2>
            <div
              style="inline-size: 100px; block-size: 4px; margin-block-start: 20px; margin-block-end: 20px; border-radius: 5px; background-color: #ff7f15"
            />
            <p class="mb-5">With FabriXAPI, you have the power to take control of the subscription process, approve API subscriptions, and terminate or stop renewals. It empowers you to effectively manage the lifecycle of your subscriptions.</p>
            <router-link :to="{ path: `/subscription-management`}" class="font-weight-bold" style="color:#ff7f15">
              Learn More <i class="far fa-arrow-right ml-1" />
            </router-link>
          </div>
          <div class="col-md-6 order-md-1">
            <img class="img-fluid rounded shadow" src="@/assets/svg/illustrations/subscription-contract.svg">
          </div>
        </div>
      </div>

      <!-- Developer Management -->
      <div class="p-5 p-lg-7" style="background-color: #f0fbf8; border-radius: 16px">
        <div class="row align-items-center">
          <div class="col-md-6 mb-5 mb-md-0 pr-md-5">
            <span class="d-block small font-weight-bold text-cap mb-2">Maintain It</span>
            <h2>Developer Management</h2>
            <div
              style="inline-size: 100px; block-size: 4px; margin-block-start: 20px; margin-block-end: 20px; border-radius: 5px; background-color: #35c8a0"
            />
            <p class="mb-5">Obtain valuable user insights by accessing information about registered API users on your portal. With the added feature of sending announcements, you can efficiently communicate updates to all or specific subscriber groups.</p>
            <router-link :to="{ path: `/developer-management`}" class="font-weight-bold" style="color:#35c8a0">
              Learn More <i class="far fa-arrow-right ml-1" />
            </router-link>
          </div>
          <div class="col-md-6">
            <img class="img-fluid rounded shadow" src="@/assets/svg/illustrations/developer-management.png">
          </div>
        </div>
      </div>

    </div>
    <!-- ===== End Main Feature ===== -->

    <!-- ===== Benefits ===== -->
    <!-- <div class="container space-2 space-lg-3">
      <div class="w-md-85 w-lg-75 text-center mx-md-auto mb-9">
        <h2>The Future of API Management is Here</h2>
        <p>The FabriX API Portal serves as a unified platform for developers to collaborate with APIs, empowering businesses to efficiently build and manage their API programs.</p>
      </div>
      <div class="row">
        <div class="col-md-4 mb-7 mb-md-0">
          <div class="text-center px-lg-3">
            <figure class="max-w-10rem mx-auto mb-3">
              <img class="img-fluid" src="@/assets/svg/icons/icon-1.svg">
            </figure>
            <h4>Build Your API Presence in Minutes</h4>
            <p>Quickly establish your API presence with FabriXAPI's easy-to-use tools, enabling fast and efficient API deployment.</p>
          </div>
        </div>

        <div class="col-md-4 mb-7 mb-md-0">
          <div class="text-center px-lg-3">
            <figure class="max-w-10rem mx-auto mb-3">
              <img class="img-fluid" src="@/assets/svg/icons/icon-31.svg">
            </figure>
            <h4>Convert More API Sales and Adoptions</h4>
            <p>Leverage with the Pre-built API Subscription Management for Quality of Services (QoS) to increase API sales and adoptions.</p>
          </div>
        </div>

        <div class="col-md-4">
          <div class="text-center px-lg-3">
            <figure class="max-w-10rem mx-auto mb-3">
              <img class="img-fluid" src="@/assets/svg/icons/icon-7.svg">
            </figure>
            <h4>Accelerate Collaboration and Ecosystem Development</h4>
            <p>Facilitate rapid collaboration and foster API ecosystem growth through streamlined processes and enhanced developer interactions.</p>
          </div>
        </div>
      </div>

    </div> -->
    <!-- ===== End Benefits ===== -->

    <!-- ===== More Features ===== -->
    <div class="container space-2">
      <div class="w-md-85 text-center mx-md-auto mb-7">
        <h2>The All-in-one API Portal You Will Love</h2>
        <p>Uncover more powerful features of the FabriXAPI Portal.</p>
      </div>

      <div class="row mx-n2 mx-lg-n3">
        <div class="col-lg-6 mb-5">
          <div class="card h-100 feature-card" style="background-color: #f9fafb;">
            <!-- <img src="https://assets.website-files.com/61d72a2cda50bc679e28766b/64ad57e99cd8a851ddcfcd04_blueish.png" class="feature-card-bg"> -->
            <img src="@/assets/img/products/developer-portal/feature-onboarding.jpg" class="feature-card-img img-fluid p-4">
            <div class="feature-card-text">
              <h3>API Developer Onboarding</h3>
              <p class="mb-0">Efficient and streamlined self-serving onboarding process for API developers to use your APIs.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-5">
          <div class="card h-100 feature-card" style="background-color: #f9fafb;">
            <!-- <img src="https://assets.website-files.com/61d72a2cda50bc679e28766b/64ad57de0ec8d4b7dc92787e_Ellipse%2011.png" class="feature-card-bg"> -->
            <img src="@/assets/img/products/developer-portal/feature-pricing.jpg" class="feature-card-img img-fluid p-4">
            <div class="feature-card-text">
              <h3>API Subscription Management</h3>
              <p class="mb-0">Pre-built API Subscription Management for Quality of Services (QoS).</p>
            </div>
          </div>
        </div>
        <div class="col-lg-12 mb-5">
          <div class="card h-100 feature-card" style="background-color: #f9fafb">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <img src="@/assets/img/products/developer-portal/portal-metrics.jpg" class="img-fluid p-4">
              </div>
              <div class="col-lg-6">
                <div class="px-6 pb-6 pt-lg-6">
                  <h3>Portal Metrics at Your Fingertips</h3>
                  <p class="mb-0">Access comprehensive Portal metrics instantly for informed decision-making and optimization.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="col-lg-12 mb-5">
          <div class="card h-100 feature-card" style="background-color: #f9fafb">
            <div class="row align-items-center">
              <div class="col-lg-6 order-lg-2">
                <img src="@/assets/img/products/developer-portal/feature-metrics.jpg" class="img-fluid p-4">
              </div>
              <div class="col-lg-6 order-lg-1">
                <div class="px-6 pb-6 pt-lg-6">
                  <h3>Analyze API Usage</h3>
                  <p class="mb-0">Gain insights into API usage patterns for informed decision-making.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="card h-100 feature-card" style="background-color: #f9fafb;">
            <!-- <img src="https://assets.website-files.com/61d72a2cda50bc679e28766b/64ad57de0ec8d4b7dc92787e_Ellipse%2011.png" class="feature-card-bg"> -->
            <img src="@/assets/img/products/developer-portal/dp-endpoint.jpg" class="feature-card-img img-fluid p-4">
            <div class="feature-card-text">
              <h3>API Docs Management</h3>
              <p class="mb-0">Enable interactive API Document with try it out features for a better developer experience (DX).</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="card h-100 feature-card" style="background-color: #f9fafb;">
            <!-- <img src="https://assets.website-files.com/61d72a2cda50bc679e28766b/64ad57e99cd8a851ddcfcd04_blueish.png" class="feature-card-bg"> -->
            <img src="@/assets/img/products/developer-portal/feature-key.jpg" class="feature-card-img img-fluid p-4">
            <div class="feature-card-text">
              <h3>API Key Management</h3>
              <p class="mb-0">Efficiently handle and secure API keys for seamless integration management.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="card h-100 feature-card" style="background-color: #f9fafb;">
            <!-- <img src="https://assets.website-files.com/61d72a2cda50bc679e28766b/64ad57de0ec8d4b7dc92787e_Ellipse%2011.png" class="feature-card-bg"> -->
            <img src="@/assets/img/products/developer-portal/feature-limit.jpg" class="feature-card-img img-fluid p-4">
            <div class="feature-card-text">
              <h3>API Rate Control</h3>
              <p class="mb-0">Ensure optimal performance and protect APIs with flexible rate control.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ===== End More Features ===== -->

    <!-- ===== List on OpenAPIHub ===== -->
    <div class="bg-light">
      <div class="container space-2">
        <div class="row align-items-center">
          <div class="col-12 col-md-6 mb-2 mb-md-0 order-md-2">
            <div class="h4 mb-3">
              <span class="badge badge-pill badge-soft-success px-3">✨ New Feature</span>
            </div>
            <h2 class="mb-3">Connect Your APIs to the World</h2>
            <p>Looking to maximize the exposure of your APIs? Now you can showcase your APIs on <strong>OpenAPIHub</strong>, a public API Directory that connects you with over 15,000+ API users worldwide, all at no cost. As the top API Directory in APAC, OpenAPIHub offers you an excellent opportunity to increase visibility for your APIs and API Portal, reaching a wider audience and gaining valuable exposure.</p>
            <div class="mb-3">
              <a href="https://blog.openapihub.com/en-us/list-api-on-openapihub/" class="btn btn-primary btn-wide" target="_blank">Discover how to list APIs</a>
            </div>
            <a href="https://hub.openapihub.com/" class="btn btn-outline-primary btn-white" target="_blank">Visit OpenAPIHub API Directory</a>
          </div>
          <div class="col-12 col-md-6 order-md-1">
            <div class="w-80 w-md-100 w-lg-75 mx-auto">
              <img class="img-fluid" src="@/assets/svg/illustrations/api-world.svg">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ===== End List on OpenAPIHub ===== -->

    <!-- ===== Trust ===== -->
    <div class="container space-2">
      <div class="w-lg-65 text-center mx-lg-auto mb-6">
        <h2>Enterprise Level Trust & API Security</h2>
        <p>FabriXAPI is an award-winning SaaS API platform trusted by hundreds of API providers in different scales and sectors.</p>
      </div>

      <div class="row justify-content-md-center">
        <div class="col-md-4 mb-6 mb-lg-0">
          <div class="step-dots text-center px-lg-4">
            <figure class="max-w-10rem mx-auto mb-3">
              <img class="img-fluid" src="@/assets/svg/icons/icon-48.svg">
            </figure>
            <h4>ISO 27001:2013 Certified</h4>
          </div>
        </div>

        <div class="col-md-4 mb-6 mb-lg-0">
          <div class="step-dots text-center px-lg-4">
            <figure class="max-w-10rem mx-auto mb-3">
              <img class="img-fluid" src="@/assets/svg/illustrations/aws-certified.svg">
            </figure>
            <h4>AWS Qualified Software</h4>
          </div>
        </div>

        <div class="col-md-4">
          <div class="text-center px-lg-4">
            <figure class="max-w-10rem mx-auto mb-3">
              <img class="img-fluid" src="@/assets/svg/icons/icon-13.svg">
            </figure>
            <h4>Trusted by Leading Enterprises</h4>
          </div>
        </div>
      </div>

      <div class="text-center mt-6">
        <router-link :to="{ path: `/trust-certification`}" class="btn btn-primary font-weight-bold">
          Learn More
        </router-link>
      </div>

    </div>
    <!-- ===== End Trust ===== -->

    <!-- ===== Pricing ===== -->
    <div class="container space-2">
      <div class="p-6 p-lg-8 p-xl-11" style="border-radius:16px; background-color: #1c344c;">
        <div class="row align-items-center">
          <div class="col-lg-6 pr-xl-6 mb-6 mb-lg-0">
            <img class="img-fluid max-w-8rem mb-3" src="@/assets/svg/icons/icon-31.svg">
            <h2 class="text-white">Simple & Transparent Pricing</h2>
            <p class="text-white">Whether you're an individual, startup or a large organization, FabriXAPI has a plan to help you grow.</p>
            <div class="mt-6">
              <div class="media mb-4">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body text-white">
                  Generate API Portal with a Few Clicks
                </div>
              </div>
              <div class="media mb-4">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body text-white">
                  Manage APIs with Flexible Options
                </div>
              </div>
              <div class="media mb-4">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body text-white">
                  Start API Monetization in Minutes
                </div>
              </div>
              <div class="media mb-4">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body text-white">
                  Convert More API Sales & API Adoptions
                </div>
              </div>
            </div>

          </div>
          <div class="col-lg-6">
            <div class="card shadow p-4 p-md-7">
              <span class="text-dark text-center">
                <h5>Starting at</h5>
                <h1>FREE</h1>
              </span>
              <hr class="my-4">
              <div class="mb-2 text-center">
                <p>No credit card is required.</p>
              </div>
              <div class="text-center">
                <a class="btn btn-primary btn-block mb-3" href="https://provider-portal.fabrixapi.com/" target="_blank">
                  Start for free <i class="far fa-arrow-right ml-1" />
                </a>
                <router-link
                  :to="{ path: `/pricing`}"
                  class="font-weight-bold"
                >
                  Explore More Plans
                </router-link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- ===== End Pricing -->

    <!-- ===== Community ===== -->
    <div class="container space-top-2 space-bottom-3">
      <div class="row justify-content-lg-between align-items-center mb-3">
        <div class="col-lg-5 mb-6 mb-lg-0 order-lg-2">
          <div class="w-50 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/api-economy-community.svg">
          </div>
        </div>

        <div class="col-lg-7 order-lg-1">
          <div class="w-md-85 w-lg-100 mx-auto text-center text-lg-left">
            <div class="mb-5">
              <h2>Be Part of Our API Community</h2>
              <p>Introducing OpenAPIHub, a thriving API community that unites developers from across the globe. Discover the wealth of resources we offer and join us today to embark on an enriching journey together!</p></div>
          </div>
        </div>
      </div>
      <div class="row mx-n2">
        <div class="col-sm-6 col-lg-3 px-2 mb-3 mb-lg-0">
          <a class="card h-100 transition-3d-hover" href="https://hub.openapihub.com/" target="_blank">
            <div class="card-body">
              <figure class="w-100 max-w-8rem mb-4">
                <img class="img-fluid" src="@/assets/svg/icons/icon-52.svg" alt="SVG">
              </figure>
              <h4>API Hub</h4>
              <p class="font-size-1 text-body mb-0">Explore a vast API directory and vibrant community, offering you a platform to discover and access thousands of APIs at your fingertips.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn more <i class="far fa-arrow-right ml-1" /></span>
            </div>
          </a>
        </div>

        <div class="col-sm-6 col-lg-3 px-2 mb-3 mb-lg-0">
          <a class="card h-100 transition-3d-hover" href="https://www.openapihub.com/academy" target="_blank">
            <div class="card-body">
              <figure class="w-100 max-w-8rem mb-4">
                <img class="img-fluid" src="@/assets/svg/icons/icon-19.svg" alt="SVG">
              </figure>
              <h4>Open API Academy</h4>
              <p class="font-size-1 text-body mb-0">Immerse yourself in an API learning hub to enrich your understanding of APIs.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn more <i class="far fa-arrow-right ml-1" /></span>
            </div>
          </a>
        </div>

        <div class="col-sm-6 col-lg-3 px-2 mb-3 mb-sm-0">
          <a class="card h-100 transition-3d-hover" href="https://blog.openapihub.com/" target="_blank">
            <div class="card-body">
              <figure class="w-100 max-w-8rem mb-4">
                <img class="img-fluid" src="@/assets/svg/icons/icon-12.svg" alt="SVG">
              </figure>
              <h4>API Blog</h4>
              <p class="font-size-1 text-body mb-0">Discover an API Blog filled with insightful articles covering a wide range of API topics and knowledge.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn more <i class="far fa-arrow-right ml-1" /></span>
            </div>
          </a>
        </div>

        <div class="col-sm-6 col-lg-3 px-2">
          <a class="card h-100 transition-3d-hover" href="https://www.facebook.com/OpenAPIHub" target="_blank">
            <div class="card-body">
              <figure class="w-100 max-w-8rem mb-4">
                <img class="img-fluid" src="@/assets/svg/icons/icon-8.svg">
              </figure>
              <h4>API Community</h4>
              <p class="font-size-1 text-body mb-0">Join a vibrant API Community that brings developers from all corners of the world together, fostering connections and collaboration.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn more <i class="far fa-arrow-right ml-1" /></span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <!-- ===== End Community ===== -->

    <!-- ===== CTA ===== -->
    <div class="container space-bottom-3">
      <div class="p-5 p-lg-7 text-center" style="background: linear-gradient(225.52deg,#5ec8a1 1.29%,#74c0ff); border-radius: 16px">
        <div class="mb-5">
          <h2 class="text-white">Ready to Build Your API Store?</h2>
          <p class="lead text-white">Start building or get in touch with FabriXAPI team.</p>
        </div>
        <a class="btn btn-secondary btn-wide  mx-1 mb-2" href="https://provider-portal.fabrixapi.com/" target="_blank">Start for Free <i class="far fa-arrow-right ml-1" /></a>
        <router-link
          :to="{ path: `/contact`}"
          class="btn btn-light btn-wide mx-1 mb-2"
        >Contact Us
        </router-link>
      </div>
    </div>
    <!-- ===== End CTA ===== -->

    <!-- ===== Overview ====== -->
    <!-- <OahOverview class="space-3" /> -->
    <!-- ===== End Overview ====== -->

    <!-- ===== CTA ===== -->
    <!-- <div class="container space-2 space-lg-3">
      <div class="row justify-content-lg-between align-items-lg-center text-center text-lg-left">
        <div class="col-lg-6 mb-5 mb-lg-0">
          <h2 class="mb-2">Ready to Build Your API Store?</h2>
          <p>Start building or get in touch with FabriXAPI team.</p>
        </div>

        <div class="col-lg-5 text-lg-right">
          <a class="btn btn-primary btn-wide  mx-1 mb-2" href="https://provider-portal.fabrixapi.com/" target="_blank">Get Started for Free</a>
          <router-link
            :to="{ path: `/contact`}"
            class="btn btn-outline-primary btn-white btn-wide mx-1 mb-2"
          >Contact Us
          </router-link>
        </div>
      </div>
    </div> -->
    <!-- ===== End CTA ===== -->

  </main>

</template>

<script>
const $ = require('jquery')
import HSGoTo from '@/assets/vendor/hs-go-to/src/js/hs-go-to'
import HSVideoPlayer from '../../assets/vendor/hs-video-player/src/js/hs-video-player'
import 'bootstrap'
import '@/assets/js/hs.core.js'
import { mapGetters } from 'vuex'
// import OahOverview from '@/components/oah-overview'
export default {
  name: 'Home',
  components: {
    // OahOverview
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
    ])
  },
  mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    $('.js-inline-video-player').each(function() {
      new HSVideoPlayer($(this)).init()
    })
    $('#videoModal').on('hidden.bs.modal', function(e) {
      $('#videoModal iframe').attr('src', $('#videoModal iframe').attr('src'))
    })
  },
  created() {
  },
  methods: {
  },
  metaInfo() {
    return {
      title: 'FabriXAPI',
      titleTemplate: 'FabriXAPI | %s',
      link: [
        { rel: 'canonical', href: 'https://www.fabrixapi.com/' }
      ],
      meta: [
        { name: 'description', content: 'An award-winning one-stop API platform services connecting digital businesses to embrace Open API Economy.' },
        { property: 'og:site_name', content: 'FabriXAPI' },
        { property: 'og:title', content: 'FabriXAPI' },
        { property: 'og:description', content: 'An award-winning one-stop API platform services connecting digital businesses to embrace Open API Economy.' },
        { property: 'og:image', content: 'https://www.fabrixapi.com/assets/img/thumbnails/fabrixapi-thumbnail.jpg' },
        { property: 'og:url', content: 'https://www.fabrixapi.com/' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .feature-card {
    justify-content: flex-end;
    border-radius: 16px;
    flex-direction: column;
    align-items: flex-end;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .feature-card-bg {
    z-index: 0;
    width: 1200px;
    max-width: none;
    opacity: 1;
    display: block;
    position: absolute;
    bottom: auto;
    top: -565px;
    left: -240px;
    right: 0;
    transform: rotate(1deg)
  }

  .feature-card-img {
    z-index: 1;
    width: 100%;
    position: relative;
  }

  .feature-card-text {
    z-index: 1;
    width: 100%;
    padding: 0 2.5rem 2.5rem;
  }

</style>

